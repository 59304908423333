.footer {
    background-color: $blue;

    section:first-of-type {
        .subheading {
            padding-top: 1em;
        }
    }

    section {
        padding: 0 2em;

        &:last-of-type {
            padding-bottom: 2em;
        }
    }


    @include phone {
        padding: 0 0.5em;
    }
}