.text, .text-container {
    @include phone {
        width: 95%;
    }
}

.contents-container {
    padding: 2em 0.5em 0 0.5em;
    text-align: center;
    display: flex;
    flex-direction: column;
    
    @include phone {
        padding: 0.5em 0.5em 0 0.5em;
    }

    .section {
        margin-bottom: 1em;

        @include phone {
            margin-bottom: 0.5em;
        }

        &.subheading {
            margin-left: 1em;
            margin-right: 1em;
        }
    }

    .hero {
        width: 100%;
        margin-bottom: 2em;

        @include phone {
            margin-bottom: 1em;
        }
    }

    .text {
        width: 70%;
        align-self: center;

        @include phone {
            width: 80%;
        }
    }

    .quote {
        width: 100%;
        align-self: center;
        display: flex;
        flex-direction: column;

        .subheading {
            @include phone {
                margin-bottom: 0.25em;
            }
        }

        .quote-text {
            width: 90%;
            align-self: center;
            font-size: 25px;
        }

        .opening, .closing {
            width: 2em;

        }

        .opening {
            @include phone {
                margin-bottom: 1em;
            }
        }

        .closing {
            align-self: end;
            transform: rotate(180deg);
            @include phone {
                margin-top: 1em;
            }
        }
    }

    .half {
        display: flex;
        flex-direction: row;
        margin-bottom: 2em;

        @include phone {
            flex-direction: column-reverse;
        }

        .text {
            margin-right: 2em;

            @include phone {
                margin-right: 0;
                justify-self: center;
            }
        }

        img, .text {
            flex: 1
        }

        img {
            @include phone {
                margin-bottom: 1em;
            }
        }
    }

    .grey {
        background-image: url("https://cdn11.bigcommerce.com/s-fz2bnmwg7y/stencil/d3986c20-89af-013d-4d4c-4e14475343b5/e/d309cc30-88ee-013d-0f41-3292caeb4f80/img/jellycat-animated-cat-on-off-white-128-248.gif");
        background-repeat: no-repeat;
        background-position: right;
        margin: 0 -0.5em;
        background-color: $paper;
        min-height: 248px;
        display: flex;
        flex-direction: column;

        .subheading {
            margin-top: 1em;
        }

        .text, .text-container {
            width: 100%;
            text-align: center;

            @include phone {
                justify-self: center;
                width: 80%;
            }
        }

        .text {
            .text-container {
                @include phone {
                    width: 90%;
                    text-align: left;
                }

                div:last-of-type {
                    @include phone {
                        padding-right: 5em;
                    }
                }
            }
        }

        img {
            height: 200%;

            @include phone {
                height: 100%;
            }
        }
    }
}

.menu {
    div:last-of-type {
        margin-bottom: 1em;
    }

    .text {
        font-size: 25px;
    }

    img {
        align-self: center;
        height: 10em;
        margin-bottom: 2em;
    }
}

.text-container {
    text-align: left;
    justify-self: end;
    width: 90%;

    .button {
        margin: 1em 0;
        cursor: pointer;
    }
}

.divider {
    align-self: center;
    width: 80%;
    margin: 2em;
    border-bottom: 2px solid $light-grey;
}

.button {
    width: fit-content;
    padding: 0.75em 2em;
    background-color: $blue;
    color: $black;
    font-family: 'PlusJakartaSans-SemiBold';
}