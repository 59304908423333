@mixin phone {
    @media (max-width: 599px) { @content; }
}

@mixin tablet {
    @media (min-width: 600px) { @content; }
}

@mixin laptop {
    @media (min-width: 900px) { @content; }
}

@mixin desktop {
    @media (min-width: 1200px) { @content; }
}

$blue: #00c2de;
$black: #2c2a29;
$light-grey: #e5e5e5;
$paper: #f5f5f5;
$orange: #ff8204;