@font-face {
    font-family: 'PlusJakartaSans-ExtraLight';
    src: url('./fonts/PlusJakartaSans-ExtraLight.ttf');
}

@font-face {
    font-family: 'PlusJakartaSans-Bold';
    src: url('./fonts/PlusJakartaSans-Bold.ttf');
}

@font-face {
    font-family: 'PlusJakartaSans-SemiBold';
    src: url('./fonts/PlusJakartaSans-SemiBold.ttf');
}