@import 'mixins.scss';
@import 'fonts.scss';
@import 'menu.scss';
@import 'footer.scss';
@import 'page.scss';

body {
    margin: 0;
}

div {
    font-family: 'PlusJakartaSans-ExtraLight';
    font-size: 20px;
    color: #2c2a29;
    line-height: 2.5;

    @include phone {
        line-height: 1.5;
        font-size: 18px;
    }

    .heading {
        font-size: 50px;
        font-family: 'PlusJakartaSans-SemiBold';

        @include phone {
            font-size: 30px;
        }
    }

    .subheading {
        font-size: 35px;
        font-family: 'PlusJakartaSans-SemiBold';

        @include phone {
            font-size: 23px;
        }
    }
}