.menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px solid $light-grey;

    img {
        width: 400px;
        padding: 3em;

        @include phone {
            width: 200px;
            padding:1em 1em;
        }
    }

    .menu-item-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        div {
            padding: 0 1em 0.5em 1em;
            cursor: pointer;
            border-bottom: 2px solid white;

            &:hover {
                border-bottom: 2px solid $blue;
            }
        }
    }
}